/** MATERIAL */

import icEdit from '@iconify/icons-ic/edit';
import icImag from '@iconify/icons-ic/photo-camera';
import icAll from '@iconify/icons-ic/round-select-all';
import icAdd from '@iconify/icons-ic/add';
import icSet from '@iconify/icons-ic/settings';
import icTrash from '@iconify/icons-ic/delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icHandle from '@iconify/icons-ic/twotone-drag-indicator';
import icOffers from '@iconify/icons-ic/local-offer';
import icLink from '@iconify/icons-ic/link';
import icError from '@iconify/icons-ic/error';
import icGear from '@iconify/icons-ic/settings';
import icFav from '@iconify/icons-ic/favorite';
import icFavBorder from '@iconify/icons-ic/favorite-border';
import icStar from '@iconify/icons-ic/star';
import icStarBorder from '@iconify/icons-ic/star-border';
import icWarning from '@iconify/icons-ic/warning';
import icSuccess from '@iconify/icons-ic/check-circle';
import icMoney from '@iconify/icons-ic/attach-money';
import icTime from '@iconify/icons-ic/watch-later';

/** FONT AWESOME */

import icCopy from '@iconify/icons-fa-solid/copy';
import icPaste from '@iconify/icons-fa-solid/paste';
import icExport from '@iconify/icons-fa-solid/file-export';
import icImport from '@iconify/icons-fa-solid/file-import';
import icTarget from '@iconify/icons-fa-solid/bullseye';
import icSecretAgent from '@iconify/icons-fa-solid/user-secret';
import icAgent from '@iconify/icons-fa-solid/user-tie';
import icChart from '@iconify/icons-fa-solid/chart-line';
import icEye from '@iconify/icons-fa-solid/eye';
import icEmpty from '@iconify/icons-fa-solid/box-open';
import icCalendarCheck from '@iconify/icons-fa-solid/calendar-check';
import icToolbox from '@iconify/icons-fa-solid/toolbox';
import icSunny from '@iconify/icons-fa-solid/sun';
import icCloudy from '@iconify/icons-fa-solid/cloud-sun-rain';
import icRainy from '@iconify/icons-fa-solid/cloud-rain';
import icMoonRain from '@iconify/icons-fa-solid/cloud-moon-rain';
import icFlag from '@iconify/icons-fa-solid/flag-checkered';
import icSettleUp from '@iconify/icons-fa-solid/hand-holding-usd';

export {
    //MATERIAL
    icEdit,
    icImag,
    icAll,
    icAdd,
    icSet,
    icTrash,
    icSearch,
    icHandle,
    icOffers,
    icLink,
    icError,
    icGear,
    icFav,
    icFavBorder,
    icStar,
    icStarBorder,
    icWarning,
    icSuccess,
    icMoney,
    icTime,

    //FONT AWESOME
    icCopy,
    icPaste,
    icExport,
    icImport,
    icTarget,
    icSecretAgent,
    icAgent,
    icChart,
    icEye,
    icEmpty,
    icCalendarCheck,
    icToolbox,
    icSunny,
    icCloudy,
    icRainy,
    icMoonRain,
    icFlag,
    icSettleUp
};