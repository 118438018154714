import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';

import icCursos from "@iconify/icons-fa-solid/chalkboard-teacher";
import icHome from "@iconify/icons-ic/twotone-contacts";
import icServices from "@iconify/icons-ic/language";
import icBlog from "@iconify/icons-ic/twotone-import-contacts";
import icBusiness from "@iconify/icons-ic/twotone-supervisor-account";
import icClients from "@iconify/icons-ic/group";

import icBServices from "@iconify/icons-ic/room-service";
import icLBookings from "@iconify/icons-ic/book";

import { UserGlobals } from './user_globals';
import { icAgent, icChart, icOffers } from './pages/services/icons';
import { icSecretAgent } from  './pages/services/icons';
import { SYSTEM_TYPES } from './pages/services/SYSTEM-CONSTANTS';
import { NavigationLink } from 'src/@vex/interfaces/navigation-item.interface';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {

  icOffers = icOffers;
  icSecretAgent = icSecretAgent;
  icAgent = icAgent;
  icChart = icChart;

  title = 'vex';

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    public user_globals: UserGlobals,
    private router: Router
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap
      .pipe(
        map(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((isRtl) => {
        this.document.body.dir = isRtl ? "rtl" : "ltr";
        this.configService.updateConfig({
          rtl: isRtl,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.navigationService.items = [
      {
        type: "subheading",
        label: "Nosotros",
        children: [
          {
            type: "link",
            label: "Contacto",
            route: "/",
            icon: icHome,
            routerLinkActiveOptions: {
              exact: true,
            },
          },
          // {
          //   type: "link",
          //   label: "Destinos",
          //   icon: icServices,
          //   route: "destinos",
          // },
        ],
      },
      {
        type: "subheading",
        label: "Contenido",
        children: [
          {
            type: "link",
            label: "Galería",
            icon: icServices,
            route: "galeria",
          },
          {
            type: "link",
            label: "Comentarios",
            icon: icCursos,
            route: "comentarios",
          },
          {
            type: "dropdown",
            label: "Blog",
            icon: icBlog,
            children: [
              {
                type: "link",
                label: "Categorías",
                icon: icBlog,
                route: "blog/categorias",
              },
              {
                type: "link",
                label: "Lista Blogs",
                icon: icBlog,
                route: "blog/entradas",
              },
            ],
          },
          {
            type: "link",
            label: "FAQs",
            icon: icCursos,
            route: "faqs",
          },
          {
            type: "link",
            label: "Banners",
            icon: icCursos,
            route: "banners",
          },
          {
            type: "link",
            label: "Ubicacion",
            icon: icCursos,
            route: "ubicacion",
          },
        ],
      },
      // {
      //   type: "subheading",
      //   label: "Booking",
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Catálogo',
      //       icon: icBServices,
      //       route: SYSTEM_TYPES ? 'booking/conf/list-service-types' : 'booking/conf/list-services'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Gestión de reservas',
      //       icon: icLBookings,
      //       route: SYSTEM_TYPES ? 'booking/list/list-service-types' : 'booking/bookings/list-services'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Ofertas y promociones',
      //       icon: this.icOffers,
      //       route: 'booking/list-offers'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Agentes de ventas',
      //       icon: this.icAgent,
      //       route: 'booking/list-agents'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Informes y analíticas',
      //       icon: this.icChart,
      //       route: 'booking/analytics'
      //     }
      //   ]
      // },
      // {
      //   type: "subheading",
      //   label: "Clientes",
      //   children: [
      //     {
      //       type: "link",
      //       label: "Clientes",
      //       icon: icClients,
      //       route: "clientes",
      //     },
      //   ],
      // },
      // {
      //   type: 'dropdown',
      //   label: 'Media',
      //   icon: icLiveTv,
      //   children: [
      //     {
      //       type: 'link',
      //       label: 'Últimas noticias',
      //       route: 'news-entries'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Galería de imágenes',
      //       route: 'image-gallery'
      //     },
      //     {
      //       type: 'link',
      //       label: 'Banners de bienvenida',
      //       route: 'banners'
      //     }
      //   ]
      // },
      // {
      //   type: 'link',
      //   label: 'Mis clientes',
      //   icon: icSubscribers,
      //   route: 'customers'
      // }
    ];
  }

  async ngOnInit(): Promise<void> {
    let ref = this;
    this.router.events.subscribe((event: any) => {
      ref.user_globals.userIsChecked();
    })
    this.user_globals.userIsChecked();
  }
}
